import { FC } from 'react';
import { useController } from 'react-hook-form';

import { RadioButtonsGroupProps, RadioButtonsGroup } from '../base';

interface RhfRadioButtonsGroupProps extends RadioButtonsGroupProps {
  control: any;
  name: string;
}

const RhfRadioButtonGroup: FC<RhfRadioButtonsGroupProps> = ({
  control,
  name,
  label,
  ...otherProps
}) => {
  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue: ''
  });

  return (
    <RadioButtonsGroup
      label={label}
      helperText={error?.message}
      error={error ? true : false}
      name={name}
      {...otherProps}
      onChange={onChange}
      value={value}
    />
  );
};

export { RhfRadioButtonGroup };
