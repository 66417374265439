import { isMobile, osName } from 'react-device-detect';

export const setLoggedInUser = (user: any) => {
  if (window.localStorage) {
    if (user) {
      localStorage.setItem('timely_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('timely_user');
    }
  }
};

export const getLoggedInUser = (): any => {
  let loggedIn = null;
  if (window.localStorage) {
    loggedIn = localStorage.getItem('timely_user');
  }

  let user = null;
  if (loggedIn === null) {
    user = null;
  } else {
    user = JSON.parse(loggedIn);
  }

  return user;
};

export const resetLoggedInUser = () => {
  if (window.localStorage) {
    localStorage.removeItem('timely_user');
  }
};

export const sendMessageToParentWindow = (message: string) => {
  if (window && window.parent) {
    try {
      window.parent?.postMessage(
        { from: 'timely', action: message },
        '*'
      );
    } catch (e) {
      console.log(e);
    }
  }
};

export const deviceInfo = {
  device_type: isMobile ? 'mobile' : 'desktop',
  device_os: osName
};