import DoneIcon from '@mui/icons-material/Done';
import Flag from '@mui/icons-material/Flag';
import Autocomplete, {
  autocompleteClasses,
  AutocompleteCloseReason
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import { FC, MouseEvent, SyntheticEvent, useState } from 'react';
import { COUNTRIES } from '../../../common/constants';
import { CountryType } from '../../../common/types';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent'
      },
      '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
        backgroundColor: theme.palette.action.hover
      }
    }
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative'
  }
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128'
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${
    theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
  }`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light'
          ? 'rgba(3, 102, 214, 0.3)'
          : 'rgb(12, 45, 107)'
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd'
    }
  }
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  fontSize: 16,
  width: '100%',
  textAlign: 'left',
  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
  fontWeight: 600,
  '&:hover,&:focus': {
    color: theme.palette.mode === 'light' ? '#0366d6' : '#58a6ff',
    background: theme.palette.grey[200]
  },
  '& span': {
    width: '100%'
  },
  '& svg': {
    width: 16,
    height: 16
  }
}));

interface CountryPickerProps {
  country?: CountryType;
  showPhoneCode?: boolean;
  isAdorment?: boolean;
  disabled?: boolean;
  onChange?: (country: CountryType) => void;
}

const CountryPicker: FC<CountryPickerProps> = ({
  country,
  showPhoneCode,
  isAdorment,
  disabled,
  onChange
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleWidgetClose = (selected: CountryType) => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    onChange && onChange(selected);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'github-label' : undefined;

  return (
    <>
      <Box
        sx={{
          fontSize: 14,
          display: 'flex',
          justifyContent: 'flex-start',
          width: 'max-content'
        }}
      >
        <Button
          aria-describedby={id}
          onClick={handleClick}
          disabled={disabled}
          sx={{ padding: !isAdorment ? '8px' : '12px 0px 12px 8px' }}
        >
          {country ? (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box
                component="img"
                sx={{ mr: showPhoneCode ? 1 : 0, width: 24 }}
                loading="lazy"
                src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {showPhoneCode ? `+${country.phone}` : null}
            </Box>
          ) : (
            <Flag />
          )}
        </Button>
      </Box>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              sx={{
                borderBottom: `1px solid ${
                  theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
                }`,
                padding: '8px 10px',
                fontWeight: 600
              }}
            >
              Select your country
            </Box>
            <Autocomplete
              open
              onClose={(
                event: SyntheticEvent<Element, Event>,
                reason: AutocompleteCloseReason
              ) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={
                country
                  ? country
                  : {
                      code: 'IN',
                      label: 'India',
                      phone: '91'
                    }
              }
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                if (newValue) {
                  handleWidgetClose(newValue);
                }
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No labels"
              renderOption={(props, option, { selected }) => (
                <Box
                  component="li"
                  {...props}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center !important'
                  }}
                >
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: 1, ml: '-2px' }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden'
                    }}
                  />
                  <Box
                    component="img"
                    loading="lazy"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                    sx={{ mr: 1, width: 20 }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      '& span': {
                        color: theme.palette.mode === 'light' ? '#586069' : '#8b949e'
                      }
                    }}
                  >
                    <Box component="span" sx={{ fontWeight: 600, mr: 1 }}>
                      +{option.phone}
                    </Box>
                    <Box component="span">{option.label}</Box>
                  </Box>
                </Box>
              )}
              options={COUNTRIES}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Filter labels"
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export { CountryPicker };
