import config, { APP_STAGE_PRODUCTION } from '../../../config';
import { EventData, EventNames } from './types';

export const trackGa4AnalyticsEvent = (
  eventName: keyof EventNames,
  event_data: EventData
) => {
  if (config.APP_STAGE !== APP_STAGE_PRODUCTION) {
    console.log('GA4 tracking: ', { eventName, event_data });
  }
  if (window && window.parent) {
    try {
      window.parent?.postMessage(
        { event: eventName, data: { from: 'timely-ga4', ...event_data } },
        `${config.HOST_TARGET_ORIGIN}`
      );
    } catch (e) {
      console.log(e);
    }
  }
};
