import { FC } from 'react';
import Container from '@mui/material/Container';
import { GlobalLoader, TimelyToastContainer } from '../components';

const AuthLayout: FC = (props) => {
  return (
    <>
      <Container sx={{ height: '100vh', padding: 0 }} maxWidth={false}>
        {props.children ? props.children : ''}
      </Container>
      <GlobalLoader />
      <TimelyToastContainer />
    </>
  );
};

export { AuthLayout };
