import MuiAutocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MuiTextField from '@mui/material/TextField';
import { FC, SyntheticEvent } from 'react';
import theme from '../../../theme';

export interface TimelyAutoCompleteOption {
  label: string;
  value: string;
}

export interface TimelyAutoCompleteProps {
  name: string;
  label?: string;
  options: TimelyAutoCompleteOption[];
  placeholder?: string;
  helperText?: string;
  fullWidth?: boolean;
  error?: boolean;
  includeNone?: boolean;
  value?: string;
  inputRef?: any;
  startAdornment?: JSX.Element;
  onBlur?: () => void;
  onChange?: (value: TimelyAutoCompleteOption) => void;
  type?: 'default' | 'small';
}

const AutoComplete: FC<TimelyAutoCompleteProps> = ({
  name,
  placeholder,
  label,
  options,
  error,
  helperText,
  fullWidth,
  value,
  type = 'default',
  inputRef,
  startAdornment,
  onBlur,
  onChange
}) => {
  const handleChange = (
    event: SyntheticEvent<Element, Event>,
    selectedValue: unknown
  ) => {
    onChange && onChange(selectedValue as TimelyAutoCompleteOption);
  };

  const val = options.find((option) => option.value === value);
  const valueFinal = val ? val : null;

  return (
    <Box
      sx={{
        minWidth: fullWidth ? 'auto' : 160,
        width: fullWidth ? '100%' : 'auto',
        cursor: 'pointer',
        '& .MuiTextField-root': {
          mb: type !== 'default' ? 0 : undefined,
          mt: type !== 'default' ? 0 : undefined,
          cursor: 'pointer'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          [theme.breakpoints.up('md')]: {
            borderColor: type !== 'default' ? 'white' : undefined
          }
        },
        '.MuiAutocomplete-root fieldset:hover': {
          [theme.breakpoints.up('md')]: {
            borderColor: 'rgb(208, 208, 208)'
          }
        }
      }}
    >
      {label && (
        <InputLabel error={error} shrink={false}>
          {label}
        </InputLabel>
      )}
      <MuiAutocomplete
        disablePortal
        disableClearable
        fullWidth={fullWidth}
        id={`autocomplete-${name}`}
        onChange={handleChange}
        // @ts-ignore - https://github.com/mui/material-ui/issues/18173
        value={valueFinal}
        placeholder={placeholder}
        sx={{
          mt: label ? 2 : 0,
          '& .MuiOutlinedInput-root': {
            paddingRight: '9px !important',
            marginTop: type === 'small' ? '0 !important' : undefined
          }
        }}
        renderInput={(props) => {
          return (
            <MuiTextField
              inputRef={inputRef}
              placeholder={placeholder}
              name={name}
              margin={'dense'}
              error={error}
              {...props}
              InputProps={{
                ...props.InputProps,
                startAdornment: startAdornment,
                error: error
              }}
              inputProps={{
                ...props.inputProps,
                sx: {
                  fontSize: type === 'default' ? 16 : 14,
                  padding:
                    type === 'default'
                      ? '11px 12px !important'
                      : '6px 4px 6px 10px !important'
                }
              }}
            />
          );
        }}
        options={options}
        onBlur={onBlur}
        renderOption={(props: any, option: TimelyAutoCompleteOption) => (
          <Box
            component="li"
            sx={{ fontSize: type === 'default' ? '16px' : '14px' }}
            {...props}
          >
            {option.label}
          </Box>
        )}
      />
      {helperText && (
        <FormHelperText error={error} sx={{ fontWeight: 500, mt: 0 }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export { AutoComplete };
