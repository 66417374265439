import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { FC, useRef } from 'react';

const SecondaryHeader: FC = ({ children }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  return (
    <>
      <Box
        sx={{
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.05)',
          position: 'absolute',
          top: 66,
          left: 0,
          width: '100%',
          background: 'white'
        }}
        ref={ref}
      >
        <Container maxWidth="lg">{children}</Container>
      </Box>
      <Box sx={{ height: ref.current ? ref.current.clientHeight + 20 : 98 }} />
    </>
  );
};

export { SecondaryHeader };
