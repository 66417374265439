import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { Button } from '../buttons';
import theme from '../../theme/index';

interface InfoProps {
  info: string;
  type: 'info' | 'pageinfo';
  cta?: string;
  loading?: boolean;
  xsOnly?: boolean;
  onClickCta?: () => void;
}

const Info: React.FC<InfoProps> = React.memo(
  ({ type, info, cta, xsOnly, loading, onClickCta }) => {
    const infoComponent = (
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        bgcolor={type === 'info' ? '#F7F9FA' : 'rgba(30, 201, 255,0.10)'}
        justifyContent="space-between"
        alignItems="center"
        borderRadius={8}
        padding={1.1}
        marginY={1.2}
        sx={
          loading
            ? {
                width: 'auto',
                [theme.breakpoints.down('xs')]: {
                  width: '100%'
                }
              }
            : {}
        }
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box marginRight={0.8} marginTop={0.2}>
            <ErrorOutlineIcon sx={{ color: '#3DABF5', fontSize: 16 }} />
          </Box>

          <Hidden smUp>
            <Typography variant="caption" color="textPrimary">
              {info}
            </Typography>
          </Hidden>
          <Hidden xsDown>
            <Typography variant="body2" color="textPrimary">
              {info}
            </Typography>
          </Hidden>
          {loading && (
            <CircularProgress
              color="primary"
              size={20}
              style={{ marginLeft: 10, marginRight: 10 }}
            />
          )}
        </Box>

        {cta && onClickCta && (
          <Box
            display="flex"
            flexShrink={0}
            alignSelf="stretch"
            flexDirection="column"
          >
            <Button
              variant="text"
              color="primary"
              onClick={onClickCta}
              disableElevation
              size="small"
            >
              {cta}
            </Button>
          </Box>
        )}
      </Box>
    );
    if (xsOnly) {
      return <Hidden smUp>{infoComponent}</Hidden>;
    }
    return infoComponent;
  }
);

export { Info };
