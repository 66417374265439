import { Box, CircularProgress } from '@mui/material';

const PageLoader = () => {
  return (
    <Box
      height="100vh"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export { PageLoader };
