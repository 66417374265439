import config from '../../../config';
import ApiClient from '../client';
import { EventDetails, EventOverrideDetails } from '../../../common/types';
import { CancelAppointment } from '../../../types/index';

const { TIMELY_API } = config;

/*
API Client for Timely API interaction
*/
let client: any = null;

export const ADMIN_APPOINTMENTS_API = `scheduler/admin/appointments`;

export default {
  createClient() {
    if (!client && TIMELY_API) {
      client = new ApiClient(TIMELY_API).client;
    }
  },

  async signIn(email: string, password: string) {
    const response = await client.post(`/scheduler/admin/login`, {
      email,
      password
    });
    return response;
  },

  async resetPassword(token: string, password: string) {
    const response = await client.post(`/scheduler/admin/password_reset/confirm`, {
      token,
      password
    });
    return response;
  },

  async forgotPassword(email: string) {
    const response = await client.post(`/scheduler/admin/password_reset/request`, {
      email
    });
    return response;
  },

  async logout() {
    const response = await client.post(`/scheduler/admin/logout`);
    return response;
  },

  async getAllEvents() {
    const response = await client.get(`/scheduler/admin/events`);
    return response;
  },

  async createEvent(params: EventDetails) {
    const response = await client.post(`/scheduler/admin/event`, params);
    return response;
  },

  async getEventDetails(eventSlug: string) {
    const response = await client.get(`/scheduler/admin/event/${eventSlug}`);
    return response;
  },

  async updateEvent(eventSlug: string, params: EventDetails) {
    const response = await client.post(
      `/scheduler/admin/event/${eventSlug}/update`,
      params
    );
    return response;
  },

  async createOverride(eventSlug: string, params: EventOverrideDetails) {
    const response = await client.post(
      `/scheduler/admin/event/${eventSlug}/override`,
      params
    );
    return response;
  },

  async getEventOverrides(eventSlug: string, params?: any) {
    const response = await client.get(
      `/scheduler/admin/event/${eventSlug}/overrides`,
      { params: params || {} }
    );
    return response;
  },

  async getAppointments(queryParamString?: string) {
    const response = await client.get(
      `/${ADMIN_APPOINTMENTS_API}?${queryParamString}`
    );
    return response;
  },

  async getEventSnapshot(eventSlug: string, params: any) {
    const response = await client.get(
      `/scheduler/admin/event/${eventSlug}/snapshot`,
      { params }
    );
    return response;
  },

  async getAppointmentFilters() {
    const response = await client.get(`/scheduler/admin/appointments/filters`);
    return response;
  },

  async cancelAppointment(appmntId: string, data: CancelAppointment) {
    const response = await client.post(
      `/scheduler/customer/appointment/${appmntId}/cancel`,
      data
    );
    return response;
  }
};
