import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MuiTimePicker from '@mui/lab/TimePicker';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MuiTextField from '@mui/material/TextField';
import moment, { MomentInput } from 'moment';
import { FC } from 'react';
import { FormControl } from './FormControl';

export interface TimelyTimePickerProps {
  name: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  value?: any;
  inputRef?: any;
  fullWidth?: boolean;
  mini?: boolean;
  onBlur?: () => void;
  onChange?: (value: unknown) => void;
}

const TimePicker: FC<TimelyTimePickerProps> = ({
  name,
  label,
  error,
  helperText,
  placeholder,
  value,
  inputRef,
  fullWidth,
  mini = false,
  onBlur,
  onChange
}) => {
  const handleChange = (date: MomentInput) => {
    onChange && onChange(moment(date).format('HH:mm'));
  };

  return (
    <FormControl>
      {label && (
        <InputLabel error={error} shrink={false}>
          {label}
        </InputLabel>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiTimePicker
          inputFormat="HH:mm"
          renderInput={(props) => {
            return (
              <MuiTextField
                inputRef={inputRef}
                placeholder={placeholder}
                name={name}
                margin={'dense'}
                error={error}
                onBlur={onBlur}
                {...props}
                InputProps={{
                  ...props.InputProps,
                  error: error
                }}
                inputProps={{
                  ...props.inputProps
                }}
                fullWidth={fullWidth}
              />
            );
          }}
          onChange={handleChange}
          value={value ? moment(value, 'HH:mm') : null}
        />
      </LocalizationProvider>
      {helperText && (
        <FormHelperText
          id="component-error-text"
          error={error}
          sx={{ fontWeight: 500, mt: 0 }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { TimePicker };
