import { FC, forwardRef } from 'react';
import NumberFormat from 'react-number-format';

import { SxProps, Theme } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MuiTextField from '@mui/material/TextField';

import { FormControl } from './FormControl';

interface NumberFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatNoDecimal = forwardRef<NumberFormat<any>, NumberFormatCustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        allowNegative={false}
        decimalScale={0}
        isNumericString
        prefix=""
      />
    );
  }
);

const NumberFormatWithDecimal = forwardRef<
  NumberFormat<any>,
  NumberFormatCustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      thousandsGroupStyle="lakh"
      isNumericString
      allowLeadingZeros={true}
      prefix=""
    />
  );
});

export interface TimelyTextFieldProps {
  name: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  password?: boolean;
  number?: boolean;
  enableDecimal?: boolean;
  value?: any;
  inputRef?: any;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  minRows?: number;
  maxLength?: number;
  onBlur?: () => void;
  onChange?: (value: string | number) => void;
  disabled?: boolean;
  sx?: SxProps<Theme> | undefined;
}

const TextField: FC<TimelyTextFieldProps> = ({
  name,
  label,
  error,
  helperText,
  placeholder,
  password,
  number,
  enableDecimal,
  value,
  inputRef,
  fullWidth,
  rows,
  multiline,
  maxLength,
  disabled,
  sx,
  onBlur,
  onChange
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <FormControl>
      {label && (
        <InputLabel
          sx={{ fontWeight: 400, fontSize: '12px', marginBottom: '-4px' }}
          error={error}
        >
          {label}
        </InputLabel>
      )}
      <MuiTextField
        inputRef={inputRef}
        name={name}
        margin="dense"
        error={error}
        InputProps={{
          inputComponent: number
            ? enableDecimal
              ? (NumberFormatWithDecimal as any)
              : (NumberFormatNoDecimal as any) //FIX - adding , when passed number only
            : undefined,
          sx: {
            padding: 0,
            backgroundColor: disabled ? 'rgb(244, 244, 244)' : 'rgb(255, 255, 255)'
          }
        }}
        inputProps={{
          maxLength: maxLength
        }}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        value={value == 0 ? value : value ? value : ''} //we need to show 0 and not undefined, null or ''
        type={password ? 'password' : 'text'}
        fullWidth={fullWidth}
        rows={rows}
        multiline={multiline}
        disabled={disabled}
        sx={sx}
      />
      {helperText && (
        <FormHelperText
          id="component-error-text"
          error={error}
          sx={{ fontWeight: 500, mt: 0 }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { TextField };
