import { FC } from 'react';
import { useController } from 'react-hook-form';
import { Select, TimelySelectProps } from '../base';

interface RhfSelectProps extends TimelySelectProps {
  control: any;
  name: string;
}

const RhfSelect: FC<RhfSelectProps> = ({
  control,
  name,
  label,
  placeholder,
  fullWidth,
  options
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue: ''
  });

  return (
    <Select
      label={label}
      helperText={error?.message}
      placeholder={placeholder}
      error={error ? true : false}
      onChange={onChange} // send value to hook form
      onBlur={onBlur} // notify when input is touched/blur
      value={value} // input value
      name={name} // send down the input name
      fullWidth={fullWidth}
      options={options}
    />
  );
};

export { RhfSelect };
