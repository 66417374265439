import { FC } from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';

interface FormControlProps {
  fullWidth?: boolean;
  sx?: SxProps;
}

const FormControl: FC<FormControlProps> = ({ fullWidth, sx, children }) => {
  return (
    <Box
      sx={{
        mb: 1,
        width: fullWidth ? '100%' : 'auto',
        mt: 1,
        ...sx
      }}
    >
      {children}
    </Box>
  );
};

export { FormControl };
