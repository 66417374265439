import { Box, CircularProgress } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Component } from 'react';

class GlobalLoader extends Component<any, any> {
  static singletonRef: any;

  static toggle() {
    GlobalLoader.singletonRef.show();
  }

  constructor(props: any) {
    super(props);

    this.state = {
      isOpen: false
    };

    GlobalLoader.singletonRef = this;
  }

  show = () => {
    this.setState({
      isOpen: true
    });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;

    return (
      <Modal
        open={isOpen}
        onClose={this.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          sx: {
            background: 'rgba(31, 31, 31, 0.4)'
          }
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          width="100%"
        >
          <CircularProgress color="primary" size="3rem" />
        </Box>
      </Modal>
    );
  }
}

export { GlobalLoader };
