import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AuthLayout } from '../../layout';

const Page404 = () => {
  return (
    <AuthLayout>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h1" sx={{ fontWeight: 'bold' }}>
          404
        </Typography>
        <Typography variant="subtitle1">Not found</Typography>
      </Box>
    </AuthLayout>
  );
};

export default Page404;
