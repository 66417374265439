import React, { FC } from 'react';

import { CurrencyRupee } from '@mui/icons-material';
import { Chip, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

interface RadioButtonsGroupOption {
  label: string;
  value: string;
  subtext?: string;
  tag?: string;
}

export interface RadioButtonsGroupProps {
  name: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  options: RadioButtonsGroupOption[];
  value?: string;
  onChange?: (value: string) => void;
}

const RadioButtonsGroup: FC<RadioButtonsGroupProps> = ({
  name,
  label,
  error,
  options,
  helperText,
  value,
  onChange
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    onChange && onChange(value);
  };

  return (
    <FormControl>
      {label && (
        <InputLabel
          sx={{
            position: 'relative',
            display: 'block',
            transform: 'none',
            mb: 1,
            whiteSpace: 'normal',
            top: 0
          }}
          error={error}
          shrink={false}
        >
          {label}
        </InputLabel>
      )}
      <RadioGroup value={value} name={name} onChange={handleChange}>
        {options.map((option, key) => (
          <React.Fragment key={key}>
            <FormControlLabel
              value={option.value}
              control={<Radio size="small" />}
              label={
                <>
                  {option.label}
                  {option.tag ? (
                    <Chip
                      label={option.tag}
                      color="warning"
                      size="small"
                      icon={<CurrencyRupee />}
                      sx={{
                        marginLeft: 0.5,
                        height: 20,
                        background: '#FBEEEA',
                        '& .MuiChip-icon': {
                          fontSize: 14,
                          color: '#E4734F'
                        },
                        '& .MuiChip-label': {
                          fontSize: { xs: 11, sm: 12 },
                          fontWeight: 500,
                          color: '#E4734F'
                        }
                      }}
                    />
                  ) : null}
                </>
              }
              componentsProps={{ typography: { fontSize: 14 } }}
            />
            {option.subtext && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: 12,
                  paddingLeft: 3.5,
                  marginTop: -1
                }}
                color="GrayText"
                paragraph
              >
                {option.subtext}
              </Typography>
            )}
          </React.Fragment>
        ))}
      </RadioGroup>
      {helperText && (
        <FormHelperText
          id="component-error-text"
          error={error}
          sx={{ fontWeight: 500, m: 0 }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { RadioButtonsGroup };
