import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { EventDetails } from '../../common/types';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const randomcolor = (): string => {
  const allColors: string[] = ['#3DABF5', '#2962FF', '#F66D6D'];
  return allColors[Math.floor(Math.random() * allColors.length)];
};

interface EventCardProps {
  event: EventDetails;
}

export const EventCard: FC<EventCardProps> = ({ event }) => {
  const navigate = useNavigate();
  const navigateToEdit = () => {
    navigate(`/admin/event/${event.slug}/edit`);
  };

  const navigateToSnapshot = () => {
    navigate(`/admin/event/${event.slug}/snapshot`);
  };

  const handleBookAppointmentClick = () => {
    if (window != null) {
      // @ts-ignore
      window
        ?.open(`${window.location.origin}/event/${event.slug}/book`, '_blank')
        .focus();
    }
  };

  return (
    <Card
      sx={{
        minHeight: 232,
        borderRadius: 2,
        boxShadow: '0px 3px 11px rgba(0, 0, 0, 0.1)',
        margin: 1
      }}
    >
      <CardContent
        sx={{
          minHeight: 188,
          borderBottom: 1.5,
          borderBottomColor: '#DFDFDF',
          borderTop: 5,
          borderTopColor: '#0069ff'
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            lineHeight: '29px',
            fontWeight: 600,
            cursor: 'pointer'
          }}
          color="common.black"
        >
          {event.name}
        </Typography>
        <Typography
          sx={{ mt: 0.5, fontSize: 14, lineHeight: '17px', fontWeight: 400 }}
          color="text.secondary"
        >
          {`${event.duration} Min`}
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Button
            sx={{
              textTransform: 'none',
              pl: 0
            }}
            onClick={handleBookAppointmentClick}
          >
            {' '}
            View booking page
          </Button>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button
          size="small"
          sx={{
            textTransform: 'none'
          }}
          variant="text"
          startIcon={<EventNoteIcon />}
          onClick={navigateToSnapshot}
        >
          View SnapShot
        </Button>
        <Button
          size="small"
          sx={{
            textTransform: 'none'
          }}
          variant="outlined"
          onClick={navigateToEdit}
        >
          Edit
        </Button>
      </CardActions>
    </Card>
  );
};
