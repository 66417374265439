import Paper from '@mui/material/Paper';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Box from '@mui/material/Box';
import { Button } from '../../buttons/index';

interface SearchBoxProps extends InputBaseProps {
  text: string;
  onTextChange: (val: string) => void;
  onConfirm: () => void;
  onClear: () => void;
}

const SearchBox = (props: SearchBoxProps) => {
  const { text, onTextChange, onConfirm, onClear, ...other } = props;

  return (
    <Box sx={{ display: 'flex', height: '40px' }}>
      <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: { md: 478, sm: 400, xs: '100%' },
          boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.07)',
          borderRadius: 2
        }}
      >
        <IconButton sx={{ p: '10px' }} aria-label="menu">
          <SearchIcon />
        </IconButton>
        <InputBase
          value={text}
          onChange={(e: any) => onTextChange(e.target.value)}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search..."
          {...other}
        />
        {text && text.length && (
          <IconButton
            onClick={() => onClear()}
            sx={{ p: '10px' }}
            aria-label="search"
          >
            <HighlightOffIcon />
          </IconButton>
        )}
      </Paper>
      <Button
        sx={{ ml: 2, borderRadius: 2 }}
        size="small"
        variant="contained"
        onClick={() => {
          onConfirm();
        }}
      >
        Search
      </Button>
    </Box>
  );
};

export { SearchBox };
