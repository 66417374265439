import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React, { FC } from 'react';

interface FormSectionProps {
  title: string;
  subTitle?: string;
}

export const FormSection: FC<FormSectionProps> = ({ title, subTitle }) => {
  return (
    <>
      <Box display="flex" alignItems="center" sx={{ mt: 2.5, mb: 2.5 }}>
        <Box
          sx={{
            minWidth: 24,
            minHeight: 24,
            borderRadius: 12,
            backgroundColor: '#C4C4C4',
            margin: 1.5
          }}
        />
        <Box display="flex" flexDirection="column">
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              lineHeight: '20px'
            }}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 12,
                lineHeight: '20px',
                color: 'text.secondary'
              }}
            >
              {subTitle}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider sx={{ border: 1, opacity: 0.3 }} />
    </>
  );
};
