// When using TypeScript 4.x and above
import type {} from '@mui/lab/themeAugmentation';

import { createTheme } from '@mui/material/styles';

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

import 'react-toastify/dist/ReactToastify.css';
import './custom.css';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

let theme = createTheme({});

// A custom theme for this app
theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 980,
      lg: 1280,
      xl: 1536
    }
  },
  palette: {
    primary: {
      main: '#0069ff',
      contrastText: '#fff'
    },
    secondary: {
      main: '#B0D9F4',
      contrastText: '#fff'
    },
    text: {
      primary: '#1A1A1A',
      secondary: 'rgba(26, 26, 26, 0.6)'
    },
    success: {
      main: '#00C48C'
    },
    background: {
      default: 'transparent'
    },
    common: {
      black: '#000000'
    },
    infodark: {
      contrastText: '#ffffff',
      main: 'rgba(0, 0, 0, 0.6)'
    },
    grey: {
      50: '#F7F9FA',
      100: '#F2F4F5',
      200: '#E8EAEB',
      300: '#D9DDDE',
      400: '#BFC4C6',
      500: '#9CA3A5',
      600: '#737575',
      700: '#525454',
      800: '#3E4142',
      900: '#1E1F20'
    }
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 0
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: 'relative',
          fontSize: 14,
          fontWeight: 'bold',
          color: theme.palette.text.primary,
          '&.Mui-focused': {
            color: theme.palette.text.primary
          },
          whiteSpace: 'normal'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: 0
        },
        inputRoot: {
          padding: 0
        },
        input: {
          padding: '11px 12px'
        },
        endAdornment: {
          right: '4px !important'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          marginTop: 8
        },
        input: {
          padding: '11px 12px',
          fontSize: 16,
          lineHeight: 1.25
        },
        notchedOutline: {
          borderColor: '#d0d0d0'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          width: 40,
          height: 40,
          margin: '4px auto',
          fontSize: 16
        },
        today: {
          border: 'none !important',
          '&::after': {
            content: '""',
            width: 5,
            height: 5,
            position: 'absolute',
            left: 18,
            background: 'gray',
            bottom: -6,
            borderRadius: '50%'
          }
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: '100%',
          '& > div:first-of-type': {
            padding: 0,
            paddingBottom: 12,
            borderBottom: '1px solid #dadada'
          },
          '& > div > div > span.MuiTypography-root': {
            width: '100%'
          },
          maxHeight: '400px !important'
        },
        viewTransitionContainer: {
          overflow: 'hidden',
          '& div.PrivatePickersSlideTransition-root': {
            minHeight: '300px !important',
            overflow: 'hidden'
          }
        }
      }
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          width: '100%',
          minWidth: '280px',
          [theme.breakpoints.down('xs')]: {
            maxWidth: 'auto'
          },
          '& > div > div': {
            width: '100%',
            minHeight: '400px !important'
          }
        }
      }
    }
  }
});

declare module '@mui/material/styles' {
  interface Palette {
    infodark: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    infodark?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    infodark: true;
  }
}

export default theme;
