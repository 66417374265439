import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { GlobalLoader } from '../components';

const SchedulerLayout = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: { md: 'auto', sm: 'auto', xs: 'auto' },
          minHeight: { xs: '100vh', sm: 'auto', md: 'auto' },
          width: '100%',
          overflowX: 'hidden'
        }}
      >
        <Outlet />
      </Box>
      <GlobalLoader />
    </>
  );
};

export { SchedulerLayout };
