import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
// import reportWebVitals from './reportWebVitals';
import config from './config';

Sentry.init({
  dsn: config.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0,
  release: 'timely-frontend@' + config.APP_VERSION,
  environment: config.SENTRY_ENV,
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'ChunkLoadError'],
  beforeSend(event: any, hint: any) {
    // reCaptcha modules throws these exact strings,Ignoring them as it doesn't affect users
    if (
      hint.originalException === 'Timeout' ||
      hint.originalException === 'Timeout (h)'
    )
      return null;
    return event;
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
