import { FC } from 'react';
import FormControlLabel, {
  FormControlLabelProps
} from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import MuiSwitch from '@mui/material/Switch';

const TimelySwitch = styled(MuiSwitch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.common.white
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.common.white
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}));

interface TimelySwitchProps {
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  labelPlacement?: 'top' | 'bottom' | 'start' | 'end';
}

const Switch: FC<TimelySwitchProps> = ({
  checked,
  label,
  labelPlacement,
  onChange
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onChange(checked);
  };

  return (
    <FormControlLabel
      control={<TimelySwitch checked={checked} onChange={handleChange} />}
      label={label ? label : ''}
      labelPlacement={labelPlacement}
      sx={{
        ml: labelPlacement !== 'end' ? 0 : undefined
      }}
      componentsProps={{ typography: { sx: { fontWeight: 500 } } }}
    />
  );
};

export { Switch };
