import { FC } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';

import theme from '../../../theme';
import { FormControl } from './FormControl';

export interface TimelySelectOption {
  label: string | number;
  value: string | number;
}

export interface TimelySelectProps {
  name: string;
  label?: string;
  options: TimelySelectOption[];
  placeholder?: string;
  helperText?: string;
  fullWidth?: boolean;
  error?: boolean;
  includeNone?: boolean;
  value?: string;
  onBlur?: () => void;
  onChange?: (value: string | number) => void;
  type?: 'default' | 'timezone';
}

const Select: FC<TimelySelectProps> = ({
  name,
  placeholder,
  label,
  options,
  error,
  helperText,
  fullWidth,
  value,
  type = 'default',
  onBlur,
  onChange
}) => {
  type NewType = SelectChangeEvent;

  const handleChange = (event: NewType) => {
    onChange && onChange(event.target.value ? event.target.value : '');
  };

  return (
    <FormControl
      sx={{
        minWidth: 120,
        width: fullWidth ? '100%' : 'auto',
        mb: type === 'default' ? 1.25 : 0,
        mt: { xs: 1, md: 2 }
      }}
    >
      {label && (
        <InputLabel error={error} shrink={false}>
          {label}
        </InputLabel>
      )}
      <MuiSelect
        id={`select-${name}`}
        name={name}
        error={error}
        sx={{ borderRadius: type === 'default' ? 2 : 2.25 }}
        variant="outlined"
        inputProps={{
          sx: {
            fontSize: type === 'default' ? 16 : 12,
            padding: type === 'default' ? '11px 12px' : '0px 10px',
            color:
              !value || value === 'none' ? '#B1B1B1' : theme.palette.text.primary
          }
        }}
        MenuProps={{
          PaperProps: { sx: { boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)' } }
        }}
        value={value ? value : ''}
        onChange={handleChange}
        onBlur={onBlur}
        fullWidth={fullWidth}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return placeholder;
          }
          const option = options.find((option) => option.value === selected);
          return option ? option.label : '';
        }}
      >
        {!value || value === 'none ' ? (
          <MenuItem value="none" disabled>
            {placeholder}
          </MenuItem>
        ) : null}

        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && (
        <FormHelperText error={error} sx={{ fontWeight: 500, mt: 0 }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { Select };
