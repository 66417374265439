import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import AppRoutes from './routes';
import { CustomRouter } from './routes/CustomRouter';
import { history } from './routes/history';
import timelyAPI from './services/apis/timely/index';
import theme from './theme';

const App: React.FC = () => {
  timelyAPI.createClient();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CustomRouter history={history}>
        <AppRoutes />
      </CustomRouter>
    </ThemeProvider>
  );
};

export default App;
