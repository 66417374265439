import { Slide, ToastContainer } from 'react-toastify';

const TimelyToastContainer = () => {
  return (
    <ToastContainer
      containerId="global-toast-container"
      limit={3}
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Slide}
      icon={false}
    />
  );
};

export { TimelyToastContainer };
