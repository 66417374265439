import config from '../../config';

const printLogs = (params: any) => {
  if (config.LOG_ANALYTICS_EVENTS) {
    // don't remove
    console.log({ ...params });
  }
};

/**
 * Common event tracking function that will take care any tracking integrations
 *
 * @param event // should be one of the events defined in events.ts
 * @param properties // custom properties dict to be added to event
 */
export const trackEvent = (event: any, properties?: any, callback?: any) => {
  printLogs({event, properties});
  callback && callback();
};

export * from './ga4';