import { FC } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import { AuthLayout } from '../../layout';
import { Info, TimelyAlert } from '../alert';
import { Button } from '../buttons';
import config, { APP_STAGE_DEVELOPMENT, APP_STAGE_STAGING } from '../../config';

const ErrorFallback = ({
  error,
  resetErrorBoundary
}: {
  error: any;
  resetErrorBoundary: any;
}) => {
  return (
    <AuthLayout>
      <Grid container>
        <Grid item md={12} xs={12} sm={12}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <Box
              maxWidth={450}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {error.name === 'ChunkLoadError' ? (
                <>
                  <Info
                    type="info"
                    info="This application has been updated, please refresh your browser
                  to see the latest content."
                  />
                  <Box width={250}>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      fullWidth
                      onClick={resetErrorBoundary}
                    >
                      Update
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <TimelyAlert
                    type="error"
                    message="An error has occurred, please refresh and try again."
                  />
                  <Box width={250} marginTop={1.5}>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      fullWidth
                      onClick={resetErrorBoundary}
                    >
                      Refresh
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </AuthLayout>
  );
};

const TimelyErrorBoundary: FC = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, errorInfo) => {
        // Log the error to Sentry
        if (
          config.APP_STAGE === APP_STAGE_DEVELOPMENT ||
          config.APP_STAGE === APP_STAGE_STAGING
        ) {
          //don't remove
          console.log({ error, errorInfo });
        }
        // prevent reporting chunk load error
        if (error.name !== 'ChunkLoadError') {
          Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            Sentry.captureException(error);
          });
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export { TimelyErrorBoundary };
