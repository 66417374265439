import React, { FC } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider/LocalizationProvider';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker/StaticDateRangePicker';
import Popover from '@mui/material/Popover';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DateRange } from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import { Button } from '../buttons';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../theme';

interface PopoverDateRangePickerProps {
  anchorEl?: any;
  dateRange: DateRange<Date>;
  handleChange: (newValue: DateRange<Date>) => void;
  handleClose: () => void;
  onCancel?: () => void;
  onApply?: () => void;
}

const PopoverDateRangePicker: FC<PopoverDateRangePickerProps> = ({
  anchorEl,
  dateRange,
  handleChange,
  handleClose,
  onCancel,
  onApply
}) => {
  const id = anchorEl ? 'range-popover' : undefined;
  const hasValidRange = !dateRange.includes(null);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Popover
      id={id}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      PaperProps={{
        sx: {
          width: { xs: 'max-content', md: '690px' },
          '& .MuiPickerStaticWrapper-root>div>div>div': {
            paddingLeft: { xs: 0, md: 2 },
            paddingRight: { xs: 0, md: 2 }
          }
        }
      }}
    >
      <Box px={{ xs: 2, md: 0 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDateRangePicker
            displayStaticWrapperAs={!matches ? 'desktop' : 'mobile'}
            value={dateRange}
            onChange={handleChange}
            renderInput={(startProps, endProps) => <React.Fragment></React.Fragment>}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ borderTop: '1px solid #EAECF0', p: 2 }}>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          {onCancel && (
            <Button
              type="button"
              variant="outlined"
              onClick={onCancel}
              sx={{ mr: 1.5 }}
            >
              Cancel
            </Button>
          )}
          {onApply && (
            <Button disabled={!hasValidRange} variant="contained" onClick={onApply}>
              Apply
            </Button>
          )}
        </Box>
      </Box>
    </Popover>
  );
};

export { PopoverDateRangePicker };
