import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
interface Option {
  label: string;
  value: string;
}

interface CheckboxLabelsProps {
  title: string;
  options: Option[];
  selected: string[];
  onChange: (val: string) => void;
}

const CheckboxLabels = (props: CheckboxLabelsProps) => {
  const { title, options, onChange, selected } = props;

  return (
    <FormGroup sx={{ mb: 1.5 }}>
      <Typography
        variant="body2"
        sx={{ color: 'rgb(0, 0, 0, 0.5)', mb: 0.7, mt: 2 }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 300,
          overflowY: 'auto'
        }}
      >
        {options.map(({ label, value }, index) => {
          const isSelected = selected.includes(value);
          return (
            <FormControlLabel
              sx={{
                ml: 0,
                '& .MuiTypography-root': {
                  fontSize: 14,
                  fontWeight: isSelected ? 600 : 400
                }
              }}
              key={index}
              control={
                <Checkbox
                  icon={<DoneIcon style={{ color: '#FFFFFF', fontSize: 18 }} />}
                  checkedIcon={
                    <DoneIcon
                      style={{ fontSize: 18, color: '#000000', fontWeight: 600 }}
                    />
                  }
                  sx={{
                    padding: 0,
                    pr: 0.7
                  }}
                  checked={isSelected}
                  onChange={() => onChange(value)}
                />
              }
              label={label}
            />
          );
        })}
      </Box>
    </FormGroup>
  );
};

export { CheckboxLabels };
