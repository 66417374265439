import CircularProgress from '@mui/material/CircularProgress';
import { ContainerBackdrop } from './ContainerBackdrop';

const WidgetLoader = () => {
  return (
    <ContainerBackdrop>
      <CircularProgress color="primary" size="2rem" />
    </ContainerBackdrop>
  );
};

export { WidgetLoader };
