import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Outlet, useOutletContext } from 'react-router-dom';
import { GlobalLoader, Header, TimelyToastContainer } from '../components';
import { useState } from 'react';
import config from '../config';

type ContextType = { setBgColor: (color: string) => void };

const AppLayout = () => {
  const [bgColor, setBgColor] = useState<string>('white');
  return (
    <>
      <Header />
      <Box bgcolor={bgColor} sx={{ minHeight: 'calc(100vh - 110px)' }}>
        <Container maxWidth="lg" sx={{ marginTop: 4.8, pb: 5 }}>
          <Outlet context={{ setBgColor }} />
        </Container>
        <Box
          fontSize={14}
          color="#dadada"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          mt={10}
          position={{ xs: 'relative', md: 'fixed' }}
          bottom={12}
          right={12}
        >
          Timely v{config.APP_VERSION}
        </Box>
      </Box>
      <GlobalLoader />
      <TimelyToastContainer />
    </>
  );
};

export function useBgColor() {
  return useOutletContext<ContextType>();
}

export { AppLayout };
