import moment, { Moment } from 'moment-timezone';
import { cloneElement } from 'react';

import ModeNightOutlinedIcon from '@mui/icons-material/ModeNightOutlined';
import PublicIcon from '@mui/icons-material/Public';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import { Box, InputAdornment, Typography } from '@mui/material';

import { EveningSVG, SunSVG } from '../../assets/symbols';
import { ALL_TIMEZONES } from '../../common/constants';
import { CustomerEventDetails } from '../../types';
import { AutoComplete } from '../form';

const format = 'HH:mm';

const timezones = ALL_TIMEZONES.map((timezone) => ({
  label: timezone,
  value: timezone
}));

const generateTimeSlots = (start: Moment, end: Moment, duration: number) => {
  const slots = [];

  // Adjust end time if it is less than or equal to start time (next day scenario)
  if (end.isSameOrBefore(start)) {
    end.add(1, 'day');
  }

  // Generate time slots based on duration
  while (start.isBefore(end)) {
    slots.push(start.format(format));
    start.add(duration, 'minutes');
  }

  return slots;
};

type SlotPickerProps = {
  selectedTimezone: string;
  availableSlots: string[];
  selectedSlot: string | null;
  eventData: CustomerEventDetails;
  onChange: (value: string) => void;
  onTimezoneChange: (value: string) => void;
};

type SlotProps = {
  time: string;
  isSlotSelected: boolean;
  isAvailable: boolean;
  onChange: (value: string) => void;
};

const Slot = ({ time, isSlotSelected, isAvailable, onChange }: SlotProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: { xs: '80px', md: '64px' },
        fontSize: { xs: '13px', md: '11px' },
        fontWeight: 500,
        py: { xs: '6px', md: '4px' },
        border: isSlotSelected
          ? '1px solid #1482DE'
          : isAvailable
          ? '1px solid #E3E7ED'
          : '1px solid #F3F5F6',
        borderRadius: '6px',
        color: isSlotSelected ? 'white' : isAvailable ? '#1F2127' : '#969AA5',
        backgroundColor: isSlotSelected
          ? '#1482DE'
          : isAvailable
          ? '#FFFFFF'
          : '#F3F5F6',
        boxShadow: isAvailable
          ? '0px 5px 7px 0px #1F2A340A, 0px 0.5px 1px 0px #1F2A3414'
          : null,
        cursor: isAvailable ? 'pointer' : 'not-allowed'
      }}
      onClick={isAvailable && !isSlotSelected ? () => onChange(time) : undefined}
    >
      {moment(time, format).format('hh:mm A')}
    </Box>
  );
};

const SlotPicker = ({
  selectedTimezone,
  availableSlots,
  selectedSlot,
  eventData,
  onChange,
  onTimezoneChange
}: SlotPickerProps) => {
  const MORNING: string[] = [],
    AFTERNOON: string[] = [],
    EVENING: string[] = [],
    NIGHT: string[] = [];

  const timeSlots = generateTimeSlots(
    moment(eventData.open_time, format).tz(selectedTimezone),
    moment(eventData.close_time, format).tz(selectedTimezone),
    Number(eventData?.duration) ?? 30
  );

  timeSlots.forEach((slot) => {
    const [hour] = slot.split(':').map(Number);

    if (hour < 6) {
      NIGHT.push(slot);
    } else if (hour < 12) {
      MORNING.push(slot);
    } else if (hour < 17) {
      AFTERNOON.push(slot);
    } else {
      EVENING.push(slot);
    }
  });

  const SESSIONS = [
    {
      label: 'Night',
      icon: <ModeNightOutlinedIcon />,
      slots: NIGHT
    },
    {
      label: 'Morning',
      icon: <SunSVG />,
      slots: MORNING
    },
    {
      label: 'Afternoon',
      icon: <WbSunnyOutlinedIcon />,
      slots: AFTERNOON
    },
    {
      label: 'Evening',
      icon: <EveningSVG />,
      slots: EVENING
    }
  ];

  return (
    <Box sx={{ marginTop: 1 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography sx={{ fontSize: '14px' }}>Available Slots</Typography>
        <Box sx={{ minWidth: 160, maxHeight: '100px', overflow: 'hidden' }}>
          <AutoComplete
            type="small"
            name="timezone"
            placeholder="Timezone"
            fullWidth
            options={timezones}
            value={selectedTimezone}
            onChange={({ value }) => onTimezoneChange(value)}
            startAdornment={
              <InputAdornment position="start" sx={{ mr: 0 }}>
                <PublicIcon sx={{ fontSize: '20px', ml: 0.5, mr: 0 }} />
              </InputAdornment>
            }
          />
        </Box>
      </Box>
      {SESSIONS.map((session, index) => {
        return session.slots.length ? (
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginTop: 2 }}
            key={`index-${session.label}`}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '-3px',
                gap: 1
              }}
            >
              {cloneElement(session.icon, {
                sx: { fontSize: '14px', color: '#6C7680' }
              })}
              <Typography sx={{ color: '#6C7680', fontSize: '12px' }}>
                {session.label}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: 'repeat(4, 1fr)', sm: 'repeat(6, 1fr)' },
                gap: 1,
                rowGap: { xs: 2, sm: 1 }
              }}
            >
              {SESSIONS[index].slots.map((time) => {
                const isAvailable = availableSlots?.some((slot) => slot === time);
                const isSlotSelected = selectedSlot === time;
                return (
                  <Slot
                    key={`${time}-${session.label}`}
                    time={time}
                    isAvailable={isAvailable}
                    isSlotSelected={isSlotSelected}
                    onChange={onChange}
                  />
                );
              })}
            </Box>
          </Box>
        ) : null;
      })}
    </Box>
  );
};

export { SlotPicker };
