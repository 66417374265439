import { FC } from 'react';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

interface TimelyButtonProps extends ButtonProps {
  loading?: boolean;
}

const Button: FC<TimelyButtonProps> = ({
  children,
  sx,
  disabled,
  loading,
  endIcon,
  ...rest
}) => {
  return (
    <MuiButton
      {...rest}
      disableElevation
      sx={{
        textTransform: 'none',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: 1.25,
        padding: '10px 30px',
        borderRadius: 2,
        ...sx
      }}
      disabled={disabled || loading}
      endIcon={loading ? <CircularProgress size="1em" color="warning" /> : endIcon}
    >
      {children}
    </MuiButton>
  );
};

export { Button };
