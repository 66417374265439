import { FC, useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FormHelperText, InputLabel } from '@mui/material';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FormControl } from './FormControl';

export interface TimelyRichTextEditorProps {
  label?: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  value?: string;
  onChange?: (html: string) => void;
  onBlur?: () => void;
}

const RichTextEditor: FC<TimelyRichTextEditorProps> = ({
  label,
  placeholder,
  error,
  helperText,
  value,
  onChange,
  onBlur
}) => {
  const [localEditorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (value && localEditorState.getCurrentContent().hasText() == false) {
      const contentBlock = htmlToDraft(value);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [value]);

  const handleStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    if (onChange) {
      if (
        !(
          convertToRaw(editorState.getCurrentContent()).blocks.length === 1 &&
          convertToRaw(editorState.getCurrentContent()).blocks[0].text === ''
        )
      ) {
        onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      } else {
        onChange('');
      }
    }
  };

  return (
    <FormControl>
      {label && (
        <InputLabel
          sx={{
            fontSize: 14,
            fontWeight: 600,
            position: 'relative',
            display: 'block',
            transform: 'none',
            mb: 1,
            top: 0
          }}
          error={error}
          shrink={false}
        >
          {label}
        </InputLabel>
      )}
      <Editor
        editorState={localEditorState}
        onEditorStateChange={handleStateChange}
        toolbar={{
          options: ['history', 'inline', 'list', 'emoji', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
          }
        }}
        placeholder={placeholder}
        onBlur={onBlur}
      />
      {helperText && (
        <FormHelperText
          id="component-error-text"
          error={error}
          sx={{ fontWeight: 500, mt: 0 }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { RichTextEditor };
