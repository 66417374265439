import axios from 'axios';
import { upperFirst, toLower, startCase } from 'lodash';

import { TimelyToast } from '../../../components';
import envConfig, {
  APP_STAGE_DEVELOPMENT,
  APP_STAGE_STAGING
} from '../../../config';
import { history } from '../../../routes/history';
import { resetLoggedInUser } from '../../../utils';

const userFriendlyMessage = (message: string, title?: string): void => {
  const finalMEssage = upperFirst(toLower(message || ''));
  TimelyToast.error(finalMEssage, title);
};

const getClient = (baseUrl: string, headers?: Record<string, unknown>) => {
  const config: any = {
    baseURL: baseUrl,
    contentType: 'application/json'
  };

  if (headers) {
    config.headers = headers;
  }
  config.withCredentials = true;
  config.xsrfCookieName = 'csrftoken';
  config.xsrfHeaderName = 'X-CSRFToken';

  const client = axios.create(config);
  client.interceptors.request.use(
    (requestConfig) => requestConfig,
    (requestError) => {
      return Promise.reject(requestError);
    }
  );

  client.interceptors.request.use((config: any) => {
    return config;
  });

  client.interceptors.response.use(
    (response) => {
      if (
        envConfig.APP_STAGE === APP_STAGE_DEVELOPMENT ||
        envConfig.APP_STAGE === APP_STAGE_STAGING
      ) {
        //don't remove
        console.log({ response });
      }

      return Promise.resolve(response);
    },
    (error) => {
      let message = '';

      if (error.response && error.response !== null) {
        if (error.response.status >= 500) {
          message = 'Looks like an issue at server! Our team is fixing it.';
          userFriendlyMessage(message, 'Server error');
        } else if (error.response.status === 403) {
          // authentication error
          let message = error.response.data
            ? error.response.data['message'] || error.response.data['detail']
            : undefined;
          message = message ? message : 'Please login and retry!.';
          userFriendlyMessage(message, 'Error');
        } else if (error.response.status === 401) {
          // Unauthenticated request. Check if the header is present & if yes, the token is invalidated. User needs to login again
          message = 'Please login and try again';
          userFriendlyMessage('Please login and try again', 'Error');
          /*
            Why are we force logging out the user?

            Answer: We are using a component called AuthProvider to check if user is logged in or not.
            But it always checks from localStorage as is there any user details saved in it or not.
            Our applications works with cookies - and we are not checking if we've valid cookie for the user.
            Cookies maybe expired or deleted, but localstorage still saves user details.
            hence - when ever we get AUTH issues from Backend - force logging out user and clearing localStorage.
          */
          resetLoggedInUser();
          history.replace('/admin/logout');
        } else if (error.response.status === 400) {
          const data = error.response.data;
          const errorKeys = data.errors ? Object.keys(data.errors) : [];

          if (errorKeys.length > 0) {
            errorKeys.forEach((key) => {
              const isOtherErrors = key === 'other_errors';

              if (isOtherErrors) {
                const otherErrors = data.errors[key] || [];
                otherErrors.forEach((error: any) => {
                  message = error;
                  userFriendlyMessage(error, 'Error');
                });
              } else {
                message = `${startCase(key)} - ${data.errors[key]}`;
                userFriendlyMessage(message, 'Error');
              }
            });
          } else if (error.response.data.message) {
            message =
              typeof error.response.data.message === 'object'
                ? Object.keys(error.response.data.message).reduce(
                    (prev, key) =>
                      prev +
                      error.response.data.message[key].map((err: string) => err),
                    ''
                  )
                : error.response.data.message;
            userFriendlyMessage(message, 'Error');
          } else {
            message = 'Not able to process your request.';
            userFriendlyMessage(message, 'Error');
          }
        } else if (error.response.status === 404) {
          // remove or change the meassage in prod
          message = '404 : No such end point';
          userFriendlyMessage(message, 'Error');
        }
        return Promise.reject(error.response);
      } else {
        // enable once sentry is available
        // Sentry.captureException(error);
        message =
          'Not able to connect our servers due to a network error. Please try again!';
        // network error
        userFriendlyMessage(message, 'Error');
      }

      // send error forward for any further handling
      return Promise.reject(new Error(message));
    }
  );
  return client;
};

export class APIClient {
  client: any;

  constructor(baseUrl: string, headers?: Record<string, unknown>) {
    this.client = getClient(baseUrl, headers);
  }
}

export default APIClient;
