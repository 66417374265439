import React from 'react';
import Box from '@mui/material/Box';
import MuiDialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

interface DialogProps {
  isShown: boolean;
  onClose: () => void;
  hasCloseButton?: boolean;
  isMedium?: boolean;
  isSmall?: boolean;
  isVerySmall?: boolean;
  disableBackdropClick?: boolean;
  noPadding?: boolean;
}

const Dialog: React.FC<DialogProps> = ({
  children,
  isShown,
  hasCloseButton,
  disableBackdropClick,
  noPadding,
  onClose
}) => {
  return (
    <MuiDialog
      open={isShown}
      onClose={onClose}
      disableEscapeKeyDown={disableBackdropClick}
      onBackdropClick={!disableBackdropClick ? onClose : undefined}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll="body"
      PaperProps={{
        sx: {
          maxWidth: {
            xs: '100vw !important',
            sm: '100vw important',
            md: 'max-content'
          },
          margin: {
            xs: 0,
            sm: 0
          },
          verticalAlign: { xs: 'bottom', sm: 'bottom', md: 'middle' },
          padding: !noPadding ? { xs: 2, sm: 2, md: '30px 24px 30px 24px' } : 0,
          borderRadius: 1.5
        }
      }}
    >
      {hasCloseButton && (
        <Box
          position="absolute"
          sx={{
            top: 8,
            right: 8,
            zIndex: 100000
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              width: { xs: 34, sm: 38, md: 38 },
              height: { xs: 34, sm: 38, md: 38 },
              padding: { xs: 1, sm: 1, md: 1.5 },
              background: '#F2F4F5'
            }}
          >
            <CloseIcon sx={{ fontSize: { xs: 13, sm: 16, md: 16 } }} />
          </IconButton>
        </Box>
      )}
      <DialogContent
        sx={{
          height: { xs: 'auto', sm: '100%' },
          overflow: 'hidden',
          padding: 0,
          display: 'flex',
          '&:first-child': {
            paddingTop: 0
          }
        }}
      >
        {children}
      </DialogContent>
    </MuiDialog>
  );
};

export { Dialog };
