import { createContext, useContext, useState } from 'react';

import { User } from '../types';
import { getLoggedInUser, resetLoggedInUser, setLoggedInUser } from '../utils';
import timelyAPI from '../services/apis/timely/index';

interface AuthContextType {
  user: User | null;
  authError: string | null;
  authInProgress: boolean;
  signin: (email: string, password: string, callback?: VoidFunction) => void;
  forgotPassword: (email: string, callback?: VoidFunction) => void;
  resetPassword: (
    token: string,
    password: string,
    callback?: (res: any) => any
  ) => void;
  signout: (callback?: VoidFunction) => void;
}

export const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(getLoggedInUser());
  const [authError, setAuthError] = useState<string | null>(null);
  const [authInProgress, setAuthInProgress] = useState<boolean>(false);

  const signin = async (
    email: string,
    password: string,
    callback?: VoidFunction
  ) => {
    try {
      setAuthInProgress(true);
      const response: any = await timelyAPI.signIn(email, password);

      if (response.data) {
        const user = response.data;
        setUser(user);
        setLoggedInUser(user);
        setAuthError(null);
        callback && callback();
      }
    } catch (e: any) {
      setAuthError(e.message);
    } finally {
      setAuthInProgress(false);
    }
  };

  const resetPassword = async (
    token: string,
    password: string,
    callback?: (res: any) => any
  ) => {
    try {
      setAuthInProgress(true);
      const response: any = await timelyAPI.resetPassword(token, password);
      if (response.status == 200) {
        setAuthError(null);
        callback && callback({ email: response.data.data.email });
      }
    } catch (e: any) {
      setAuthError(e.message);
    } finally {
      setAuthInProgress(false);
    }
  };

  const forgotPassword = async (email: string, callback?: VoidFunction) => {
    try {
      setAuthInProgress(true);
      const response: any = await timelyAPI.forgotPassword(email);
      if (response.status == 200) {
        setAuthError(null);
        callback && callback();
      }
    } catch (e: any) {
      setAuthError(e.message);
    } finally {
      setAuthInProgress(false);
    }
  };

  const signout = async (callback?: VoidFunction) => {
    const response: any = await timelyAPI.logout();

    if (response.data) {
      setUser(null);
      resetLoggedInUser();
      callback && callback();
    }
  };

  const value = {
    user,
    authError,
    authInProgress,
    signin,
    signout,
    forgotPassword,
    resetPassword
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
