import React, { FC } from 'react';
import { SxProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import theme from '../../theme/index';

interface BackButtonProps {
  hideText?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  text?: string;
  type?: 'default' | 'colored';
  onClick: () => any;
}

export const BackButton: FC<BackButtonProps> = ({
  disabled,
  sx,
  text,
  hideText,
  type = 'default',
  onClick
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ ...sx, cursor: 'pointer' }}
      onClick={!disabled ? onClick : undefined}
    >
      <Fab
        disableRipple
        disabled={disabled}
        aria-label="like"
        size="small"
        sx={{
          width: 38,
          height: 38,
          backgroundColor: type === 'default' ? theme.palette.grey[100] : 'white',
          border: type === 'default' ? 'none' : '1px solid rgba(26, 26, 26, 0.1)',
          boxShadow: 'unset'
        }}
      >
        {type === 'default' ? (
          <ArrowBackIosNewIcon
            sx={{ fontSize: 12, color: theme.palette.text.secondary }}
          />
        ) : (
          <ArrowBack htmlColor="#0069ff" fontSize="medium" />
        )}
      </Fab>
      {!hideText && (
        <Box marginLeft={0.8} marginRight={2}>
          <Typography
            sx={{
              fontSize: '13px',
              color: theme.palette.grey[700],
              fontWeight: 500,
              lineHeight: '16px'
            }}
          >
            {text ? text : 'Back'}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
