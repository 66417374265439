import ArrowBack from '@mui/icons-material/ArrowBack';
import { Hidden, SxProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { Button } from '../buttons';

interface GoBackButtonProps {
  disabled?: boolean;
  sx?: SxProps;
  text?: string;
  noText?: boolean;
  onClick: () => void;
}

export const GoBackButton = ({
  disabled,
  sx,
  text,
  noText,
  onClick
}: GoBackButtonProps) => {
  return (
    <>
      <Hidden mdDown>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            ...sx,
            width: '64px',
            height: '30px',
            gap: 0.5,
            cursor: 'pointer',
            border: '1px solid #ECECF0',
            borderRadius: '6px',
            backgroundColor: '#ffffff',
            hover: {
              backgroundColor: '#F3F5F6'
            }
          }}
          onClick={!disabled ? onClick : undefined}
        >
          <ArrowBack htmlColor="#0069ff" style={{ fontSize: '14px' }} />
          {!noText ? (
            <Box>
              <Typography
                sx={{
                  fontSize: '13px',
                  color: '#0069ff',
                  fontWeight: 500,
                  lineHeight: '16px'
                }}
              >
                {text ? text : 'Back'}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Button
          variant="text"
          onClick={() => onClick()}
          size="small"
          sx={{
            padding: 1,
            minWidth: '24px',
            '&:MuiButtonBase-root-MuiButton-root': {
              width: '24px'
            }
          }}
        >
          <ArrowBack htmlColor="#496075" style={{ width: '24px' }} />
        </Button>
      </Hidden>
    </>
  );
};
