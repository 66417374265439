import packageJson from '../package.json';

export const APP_STAGE_DEVELOPMENT = 'dev';
export const APP_STAGE_PRODUCTION = 'prod';
export const APP_STAGE_STAGING = 'stag';

const config = {
  APP_VERSION: packageJson.version,
  APP_STAGE: process.env.REACT_APP_STAGE,
  TIMELY_API: process.env.REACT_APP_TIMELY_API,
  FALCON_API: process.env.REACT_APP_FALCON_API,
  DITTO_ID: process.env.REACT_APP_DITTO_ID,
  HOST_TARGET_ORIGIN: process.env.REACT_APP_TARGET_ORIGIN,
  FALCON_HEADER: process.env.REACT_APP_FALCON_HEADER,

  // Sentry
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV,

  // Analytics
  LOG_ANALYTICS_EVENTS: process.env.REACT_APP_LOG_EVENTS === 'true',
};

export default config;
