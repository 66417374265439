import { ArrowRight } from '@mui/icons-material';
import DateFnsAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import { forwardRef, useEffect, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import locale from 'date-fns/locale/en-US';
if (locale && locale.options) {
  locale.options.weekStartsOn = 1;
}

import { Button } from '..';
import theme from '../../theme';
import { DatewiseSlots } from '../../types';
import { WHATSAPP_LINK } from '../../common/constants';
import { WHATSAPP_BTN_CLICKED } from '../../services/analytics/events';
import { trackEvent } from '../../services/analytics';

export default function CustomDateAdapter(options: any) {
  const adapter = new DateFnsAdapter(options);

  const constructUpperObject = (text: string) => ({ toUpperCase: () => text });
  const constructDayObject = (day: string) => ({
    charAt: () => constructUpperObject(day)
  });

  return {
    ...adapter,
    getWeekdays() {
      // Feel free to replace this with custom value
      const customWeekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      // const customWeekdays = adapter.getWeekdays();
      return customWeekdays.map((day) => {
        return constructDayObject(day);
      });
    }
  };
}

type CustomPickerDayProps = PickersDayProps<Date> & {
  slotsAvailable: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'slotsAvailable'
})<CustomPickerDayProps>(({ theme, slotsAvailable }) => ({
  ...(slotsAvailable && {
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 105, 255, 0.065)',
    color: theme.palette.primary.main,
    fontWeight: 600,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white
    }
  })
})) as React.ComponentType<CustomPickerDayProps>;

export type TimelySlotPickerDisplayType = 'date-time' | 'date' | 'time';

interface TimelySlotPickerProps {
  minDate?: Date;
  isValid: boolean;
  date: Date | null;
  time: string | null;
  datewiseSlots: DatewiseSlots | null;
  loading?: boolean;
  display?: TimelySlotPickerDisplayType;
  parentHeight?: number;
  onDateChange: (selectedDate: Date | null) => void;
  onTimeChange: (selectedTime: string) => void;
  onSubmit: () => void;
  onWhatsAppClick: () => void;
}

const TimelySlotPicker = forwardRef(
  (
    {
      date,
      time,
      datewiseSlots,
      isValid,
      minDate,
      loading,
      display = 'date-time',
      parentHeight,
      onDateChange,
      onTimeChange,
      onSubmit,
      onWhatsAppClick
    }: TimelySlotPickerProps,
    ref: any
  ) => {
    const initRef = useRef<number | undefined>(parentHeight);
    const rightArrowRef = useRef<HTMLButtonElement | null>(null);
    const [noSlotsThisMonth, setNoSlotsThisMonth] = useState<string | null>(null);
    const [whatsappLink, showWhatsappLink] = useState<boolean>(false);
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('md'));

    const availableDates = datewiseSlots
      ? Object.keys(datewiseSlots)
          .filter((date) => {
            return (datewiseSlots[date] || []).length > 0;
          })
          .map((date) => moment(date, 'DD-MM-YYYY').toDate())
      : [];

    const availableMonths = datewiseSlots
      ? uniqBy(
          Object.keys(datewiseSlots)
            .filter((date) => {
              return (datewiseSlots[date] || []).length > 0;
            })
            .map((date) => moment(date, 'DD-MM-YYYY').format('MM')),
          (e: string) => e
        )
      : [];

    const containerRef = useRef<HTMLDivElement>();

    const renderSlotsDay = (
      date: Date,
      selectedDates: Array<Date | null>,
      pickersDayProps: PickersDayProps<Date>
    ) => {
      const slotsAvailable =
        availableDates.findIndex((availableDate) =>
          moment(availableDate).isSame(date)
        ) !== -1;

      return (
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          slotsAvailable={slotsAvailable}
        />
      );
    };

    useEffect(() => {
      const currentMonth = moment(Date.now()).format('MM');
      const selectedDateMonth = date ? moment(date).format('MM') : currentMonth;
      if (
        !availableMonths.includes(currentMonth) &&
        currentMonth === selectedDateMonth
      ) {
        if (availableDates.length) {
          setNoSlotsThisMonth(
            `No Slots available in ${moment(Date.now()).format('MMMM')}`
          );
          showWhatsappLink(false);
        } else {
          setNoSlotsThisMonth(`All slots are booked at the moment`);
          showWhatsappLink(true);
        }
      } else {
        setNoSlotsThisMonth(null);
      }
    }, [datewiseSlots]);

    const handleMonthChange = (date: Date) => {
      const currentMonth = moment(date).format('MM');
      const currentDateMonth = moment(Date.now()).format('MM');
      if (!availableMonths.includes(currentMonth)) {
        if (currentMonth === currentDateMonth) {
          //year might be a problem
          if (availableDates.length) {
            setNoSlotsThisMonth(
              `No Slots available in ${moment(Date.now()).format('MMMM')}`
            );
            showWhatsappLink(false);
          } else {
            setNoSlotsThisMonth(`All slots are booked at the moment`);
            showWhatsappLink(true);
          }
        } else {
          setNoSlotsThisMonth(
            `Slots not opened yet for ${moment(date).format('MMMM')}`
          );
          showWhatsappLink(true);
        }
      } else {
        setNoSlotsThisMonth(null);
      }
    };

    const gotoNextMonth = () => {
      rightArrowRef && rightArrowRef.current && rightArrowRef.current.click();
    };

    return (
      <Box
        display="flex"
        flexDirection={{ sm: !date ? 'column' : 'row', md: 'row', xs: 'column' }}
        alignItems={{ sm: !date ? 'center' : 'flex-start' }}
        position="relative"
        ref={containerRef}
      >
        <Box
          position="relative"
          width={{ xs: '100%', sm: 350 }}
          minWidth={{ sm: 350 }}
        >
          <Hidden smDown={display === 'time'}>
            {!isEmpty(noSlotsThisMonth) && !loading ? (
              <Box
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  zIndex: 100,
                  pointerEvents: 'none'
                }}
              >
                <Box
                  sx={{
                    padding: '16px 20px',
                    minHeight: '70px',
                    backgroundColor: '#ffffff',
                    borderRadius: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    boxShadow: '0px 4px 32px rgba(0, 0, 0, 0.08)',
                    pointerEvents: 'auto'
                  }}
                >
                  <Typography variant="body1" gutterBottom sx={{ fontWeight: 500 }}>
                    {noSlotsThisMonth}
                  </Typography>
                  {!whatsappLink ? (
                    <Button
                      color="primary"
                      variant="text"
                      endIcon={<ArrowRight />}
                      onClick={gotoNextMonth}
                    >
                      View next month
                    </Button>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 2
                      }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        Not a fan of waiting for slots?
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          fontSize: 16,
                          color: 'text.secondary'
                        }}
                      >
                        <Button
                          color="success"
                          startIcon={<WhatsAppIcon />}
                          onClick={() => {
                            trackEvent(WHATSAPP_BTN_CLICKED, {
                              message: noSlotsThisMonth
                            });
                            onWhatsAppClick();
                          }}
                        >
                          Whatsapp us
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            ) : null}
            <LocalizationProvider
              dateAdapter={CustomDateAdapter as any}
              locale={locale}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                label="timely-slot-picker"
                value={date}
                onChange={(newDate) => {
                  onDateChange(newDate);
                }}
                renderDay={renderSlotsDay}
                renderInput={(params) => <TextField {...params} />}
                shouldDisableDate={(date: Date) => {
                  return (
                    availableDates.findIndex((availableDate) =>
                      moment(availableDate).isSame(date)
                    ) === -1
                  );
                }}
                minDate={minDate}
                // maxDate={maxDate}
                views={['day']}
                onMonthChange={handleMonthChange}
                componentsProps={{
                  rightArrowButton: {
                    ref: rightArrowRef
                  }
                }}
              />
            </LocalizationProvider>
          </Hidden>
        </Box>

        <Hidden smDown={display === 'date'}>
          <Slide
            direction="left"
            in={date ? true : false}
            mountOnEnter
            unmountOnExit
            container={ref.current}
            timeout={500}
            style={{ zIndex: 0 }}
          >
            <Box
              sx={{
                position: 'relative',
                width: { md: 250, sm: '100%', xs: '100%' },
                mt: 1.25,
                ml: { xs: 0, sm: 3 }
              }}
            >
              <Box
                position={{ xs: 'relative', sm: 'absolute' }}
                width={{ xs: '100%', sm: '100%', md: 196 }}
              >
                <Hidden smDown>
                  {date && (
                    <Typography
                      variant="body1"
                      sx={{ textAlign: 'left', mb: 1, fontWeight: 600 }}
                    >
                      {moment(date).format('MMMM D, YYYY')}
                    </Typography>
                  )}
                </Hidden>
                <SimpleBar
                  style={{
                    maxHeight: initRef.current
                      ? matchesXs
                        ? initRef.current - 240
                        : matchesSm
                        ? initRef.current - 250
                        : initRef.current - 135
                      : 370
                  }}
                  autoHide={false}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    height="auto"
                    sx={{
                      width: '100%',
                      paddingLeft: { sm: 0, xs: 2 },
                      paddingRight: { sm: 3, xs: 2 },
                      overflowX: 'hidden'
                    }}
                  >
                    {datewiseSlots &&
                    date &&
                    datewiseSlots[moment(date).format('DD-MM-YYYY')]
                      ? datewiseSlots[moment(date).format('DD-MM-YYYY')].map(
                          (slotTime) => {
                            const selected = slotTime === time;
                            return (
                              <Box key={slotTime} width="100%" display="flex">
                                <Box
                                  sx={{
                                    width: {
                                      xs: '100%',
                                      md: selected ? '50%' : '100%'
                                    },
                                    transition: 'all 1s ease'
                                  }}
                                >
                                  <Button
                                    variant={!selected ? 'outlined' : 'contained'}
                                    color={!selected ? 'primary' : 'infodark'}
                                    sx={{
                                      whiteSpace: 'nowrap',
                                      paddingX: '8px !important',
                                      paddingY: '15px !important',
                                      mb: 1,
                                      fontSize: 14,
                                      transition: 'all 1s ease',
                                      fontWeight: 700
                                    }}
                                    onClick={() => onTimeChange(slotTime)}
                                    fullWidth
                                  >
                                    {moment(slotTime, 'HH:mm').format('hh:mm A')}
                                  </Button>
                                </Box>

                                <Slide
                                  in={selected}
                                  direction="left"
                                  timeout={500}
                                  style={{ width: !selected ? '0%' : '100%' }}
                                >
                                  <Box sx={{ ml: selected ? 1 : 0 }}>
                                    <Button
                                      disabled={!isValid}
                                      onClick={onSubmit}
                                      variant="contained"
                                      color="primary"
                                      sx={{
                                        whiteSpace: 'nowrap',
                                        paddingX: '8px !important',
                                        paddingY: '16px !important',
                                        mb: 1,
                                        fontSize: 14,
                                        color: 'white',
                                        fontWeight: 700
                                      }}
                                      fullWidth
                                    >
                                      Confirm
                                    </Button>
                                  </Box>
                                </Slide>
                              </Box>
                            );
                          }
                        )
                      : null}
                  </Box>
                </SimpleBar>
              </Box>
            </Box>
          </Slide>
        </Hidden>
      </Box>
    );
  }
);

export { TimelySlotPicker };
