import { FC } from 'react';
import Box from '@mui/material/Box';

interface ContainerBackdropProps {
  bg?: string;
  pointerEvents?:
    | 'auto'
    | 'none'
    | 'visiblePainted'
    | 'visibleFill'
    | 'visibleStroke'
    | 'visible'
    | 'painted'
    | 'fill'
    | 'stroke'
    | 'all'
    | 'inherit';
  onClick?: () => void;
}

const ContainerBackdrop: FC<ContainerBackdropProps> = ({
  children,
  bg,
  pointerEvents = 'none',
  onClick
}) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: bg ? bg : 'rgba(255, 255, 255, 0.7)',
        zIndex: 500,
        pointerEvents: pointerEvents
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export { ContainerBackdrop };
