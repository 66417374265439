import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import envConfig, { APP_STAGE_DEVELOPMENT } from '../../config';

const Header = () => {
  const auth = useAuth();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          background: 'white',
          color: 'black',
          boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)'
        }}
      >
        <Container maxWidth="lg" sx={{ padding: { md: 0, xs: 0, sm: 0 } }}>
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, fontWeight: 600 }}
            >
              <Link to={'/'} style={{ textDecoration: 'none', color: 'black' }}>
                Timely
              </Link>
            </Typography>

            <Button href="/" color="inherit" sx={{ textTransform: 'capitalize' }}>
              Home
            </Button>

            {envConfig.APP_STAGE === APP_STAGE_DEVELOPMENT ? (
              <>
                <Button
                  href="/admin/components"
                  color="inherit"
                  sx={{ textTransform: 'capitalize' }}
                >
                  Components
                </Button>
              </>
            ) : null}

            {auth.user && (
              <Button
                color="inherit"
                sx={{ textTransform: 'capitalize' }}
                href="/admin/logout"
              >
                Logout
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export { Header };
