import { FC, forwardRef, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MuiTextField from '@mui/material/TextField';

import { COUNTRIES, COUNTRY_INDIA } from '../../../common/constants';
import theme from '../../../theme';
import { CountryPicker } from './CountryPicker';
import { FormControl } from './FormControl';

interface NumberFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumberFormatNoDecimal = forwardRef<NumberFormat<any>, NumberFormatCustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        isNumericString
        prefix=""
        decimalScale={0}
      />
    );
  }
);

export interface IntlPhoneNumberProps {
  name: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  number?: boolean;
  enableDecimal?: boolean;
  value?: any;
  inputRef?: any;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  disabled?: boolean;
  onBlur?: () => void;
  onChange?: (value: string | number) => void;
}

const getNumberWithoutCode = (noWithCode: string) => {
  if (noWithCode && noWithCode.includes('-')) {
    return noWithCode.split('-')[1];
  } else {
    return noWithCode;
  }
};

const getCountryFromPhone = (noWithCode: string) => {
  if (noWithCode && noWithCode.includes('-')) {
    const countryCode = noWithCode.split('-')[0].replace('+', '');
    const country = COUNTRIES.find(({ phone }) => phone == countryCode);
    return country;
  } else {
    return undefined;
  }
};

const IntlPhoneNumber: FC<IntlPhoneNumberProps> = ({
  name,
  label,
  error,
  helperText,
  placeholder,
  value,
  inputRef,
  fullWidth,
  disabled,
  onBlur,
  onChange
}) => {
  const currentCountry = getCountryFromPhone(value) || COUNTRY_INDIA;
  const [phone, setPhoneNumber] = useState<string>('');
  const [maxLength, setMaxLength] = useState(10);

  useEffect(() => {
    if (currentCountry.phone) {
      /* If country is indian maxlength is 10 */
      if (currentCountry.phone == '91') {
        setMaxLength(10);
      } else {
        /* If country is other maxlength is 14 - country code length */
        /*
          We are using 14 because -
          Max length of country code is 4, and max length of phone number is 10 when country code length is 4.
          Total length should be max 14 or lesser.
        */
        setMaxLength(14 - currentCountry.phone.length);
      }
    }
  }, [currentCountry]);

  useEffect(() => {
    if (value) {
      const phoneNum = getNumberWithoutCode(value);
      setPhoneNumber(phoneNum);
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
    onChange && onChange(`+${currentCountry.phone}-${event.target.value}`);
  };

  return (
    <FormControl>
      {label && (
        <InputLabel
          sx={{ fontWeight: 400, fontSize: '12px', marginBottom: '-4px' }}
          error={error}
          shrink={false}
        >
          {label}
        </InputLabel>
      )}
      <MuiTextField
        inputRef={inputRef}
        name={name}
        margin="dense"
        error={error}
        disabled={disabled}
        InputProps={{
          inputComponent: NumberFormatNoDecimal as any,
          sx: {
            padding: 0
          },
          startAdornment: (
            <InputAdornment position="start">
              <CountryPicker
                country={currentCountry}
                showPhoneCode
                isAdorment
                disabled={disabled}
                onChange={(selectedCountry) => {
                  onChange && onChange(`+${selectedCountry.phone}-${phone}`);
                }}
              />
            </InputAdornment>
          )
        }}
        inputProps={{
          maxLength: maxLength,
          sx: {
            borderRadius: 1,
            position: 'relative',
            backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2b2b2b',
            fontSize: 16,
            padding: '11px 12px 11px 0px'
          }
        }}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        value={phone ? phone : ''}
        type="text"
        fullWidth={fullWidth}
      />
      {helperText && (
        <FormHelperText
          id="component-error-text"
          error={error}
          sx={{ fontWeight: 500, mt: 0 }}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export { IntlPhoneNumber };
