import { FC } from 'react';
import { useController } from 'react-hook-form';
import { RichTextEditor, TimelyRichTextEditorProps } from '../base';

interface RhfRichTextEditorProps extends TimelyRichTextEditorProps {
  control: any;
  name: string;
}

const RhfRichTextEditor: FC<RhfRichTextEditorProps> = ({
  control,
  name,
  label,
  placeholder
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue: ''
  });

  return (
    <RichTextEditor
      label={label}
      helperText={error?.message}
      error={error ? true : false}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
    />
  );
};

export { RhfRichTextEditor };
